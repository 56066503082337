import React from "react"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"

import imgSoftskills from '../images/course_softskills.png';
import imgNlp from '../images/course_nlp.png';
import imgReinventa from '../images/course_reinventa.png';

const Courses = ({ intl }) => (
    <Layout>
        <SEO lang={intl.locale} title={intl.formatMessage({ id: "courses.title" })} />
        <h1>
            <FormattedMessage id="courses.title_page" />
        </h1>

        <h3>
            <FormattedMessage id="courses.course_softskills_title" />
        </h3>
        <p>
            <FormattedMessage id="courses.course_softskills_text" />
        </p>
        <p>
            <a href="https://www.udemy.com/course/soft-skills-for-smart-people/" target="_blank">
                <img src={imgSoftskills} alt="Course Soft Skills for Smart People" width="750" />
            </a>
        </p>

        <h3>
            <FormattedMessage id="courses.course_nlp_title" />
        </h3>
        <p>
            <FormattedMessage id="courses.course_nlp_text" />
        </p>
        <p>
            <a href="https://www.udemy.com/course/pnl-excelenciaylogro/" target="_blank">
                <img src={imgNlp} alt="Course NLP" width="750" />
            </a>
        </p>

        <h3>
            <FormattedMessage id="courses.course_reinventa_title" />
        </h3>
        <p>
            <FormattedMessage id="courses.course_reinventa_text" />
        </p>
        <p>
            <a href="https://www.udemy.com/course/reinventa-tu-vida-coaching/" target="_blank">
                <img src={imgReinventa} alt="Curso Reinventa tu vida" width="750" />
            </a>
        </p>



    </Layout >
)

export default injectIntl(Courses)
